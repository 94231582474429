@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply bg-basic_app_bg
}
li {
    list-style: none;
}
input[type='checkbox']:checked {
    background-image: url('./assets/images/checkbox.svg');
    background-size: 75% 100%;
}

.blockAfter::after{
    content: '';
    position: absolute;
    right: 5px; top: -20px;
    border: 10px solid transparent;
    border-bottom: 10px solid white;
}
circle {
    fill: none;
}
.shadow {
    filter:  drop-shadow( 0px 2px 2px rgba(135, 115, 115, 0.15));
}
    .inputLabel  {
        @apply font-[500] w-[269px] text-[14px] leading-[110%] tracking-tight text-1color
    }

/* date-picker */
.react-datepicker {
    @apply m-[10px_0px_0px_-15px]
}
.react-datepicker__current-month,
.react-datepicker-year-header {
    display: none;
}
.react-datepicker-time__header,
.react-datepicker__header__dropdown, .react-datepicker__header__dropdown--select {
    border-radius: 10px 10px 0 0;
    text-transform: capitalize;
    @apply flex items-center justify-center bg-action text-[#fff] text-[15px] leading-[18px] w-full h-[36px]
 }
.react-datepicker__month-select {
    border: none;
    background: transparent;
    color: #fff;
    padding: 0 3px 0 0;
    text-transform: capitalize;
}
.react-datepicker__year-select{
    border: none;
    background: transparent;
    color: #fff;
    padding: 0;
}
.react-datepicker__month-select option, .react-datepicker__year-select option{
    color:#000;
}
.react-datepicker__year-option {
    color: #fff;
}
.react-datepicker__month-option {
    color: #fff;
}
.react-datepicker__month-select:focus {
    box-shadow: none;
}
.react-datepicker__year-select:focus {
    box-shadow: none;
}

.react-datepicker-popper {
    z-index: 9999;
}
.react-datepicker {
    box-shadow: 0 8px 60px rgba(61, 74, 88, 0.1), 0 8px 25px rgba(61, 74, 88, 0.08);
    @apply w-[248px] min-h-[221px] rounded-[10px] border-none bg-[#fff]
}
.react-datepicker__navigation {
    z-index: 2;
    position: absolute;
    width: 8.62px;
    height: 14.2px;
    overflow: hidden;
}

.react-datepicker__navigation-icon::before {
    content: '';
    border-color: #fff;
    border-width: 1px 1px 0 0;
    display: block;
    height: 8px;
    width: 8px;
    margin-top: 3px;
}

.react-datepicker__navigation--next {
    right: 0;
    margin: 11.9px 14.22px 11.9px 0;
}

.react-datepicker__navigation--next .react-datepicker__navigation-icon::before {
    rotate: 45deg;
    margin-left: -2px;
}

.react-datepicker__navigation--previous {
    margin: 11.9px 0 11.9px 14.22px;
}

.react-datepicker__navigation--previous .react-datepicker__navigation-icon::before {
    rotate: -135deg;
    margin-left: 2px;
}

.react-datepicker__triangle {
    left: 50% !important;
    transform: translate(-50%, -6.73px) rotate(45deg) !important;
    @apply w-[18px] h-[18px] bg-action
}

.react-datepicker__header {
    /*z-index: 1;*/
    @apply relative flex justify-center flex-wrap bg-transparent pt-0 border-none
}

.react-datepicker__day-names {
    width: 212px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 0 11px 0;
}

.react-datepicker__day-names .react-datepicker__day-name {
    @apply w-[20px] h-[13px] text-[12px] font-[600] leading-[13px] tracking-tight text-3color m-[0px_12px_0px_0px] uppercase
}

.react-datepicker__day-names .react-datepicker__day-name:last-child,
.react-datepicker__day-names .react-datepicker__day-name:nth-child(6) {
    @apply text-action
}

.react-datepicker__month {
    @apply flex flex-col m-[0px_15px_0px_15px] p-[12px_0px_0px_0px] border-solid border-t-[1px] border-b_light
}
.react-datepicker-time__caption {
    display: none;
}
/*.react-datepicker-time__input {*/
/*    display: flex;*/
/*    width: 100%;*/
/*    padding-right: 40px;*/
/*    padding-left: 40px;*/
/*    outline-width: 3px!important;*/
/*    !* outline: none!important; *!*/
/*    padding-top: 10px;*/
/*    padding-bottom: 10px;*/
/*    outline-color: var(--speech_analitics)!important;*/
/*    border: none!important;*/
/*    --tw-ring-shadow: 0 0 #000 !important;*/
/*}*/

.react-datepicker__week {
    display: flex;
}
.react-datepicker__day{
    @apply px-[4px] outline-none
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    @apply flex justify-center items-center w-[24px] h-[20px] m-[0px_4px_13px_4px] text-3color text-[12px] font-[600] cursor-pointer
    leading-[13px] tracking-tight transition-all rounded-[9px]
}

.react-datepicker__day-name:first-child,
.react-datepicker__day:first-child,
.react-datepicker__time-name:first-child {
    margin-left: 0;
}

.react-datepicker__day-name:last-child,
.react-datepicker__day:last-child,
.react-datepicker__time-name:last-child {
    margin-right: 0;
}

.react-datepicker__day-name {
    cursor: default;
}

.react-datepicker__day:hover {
    @apply border-[1px] border-solid border-action
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    @apply border-[1px] border-solid border-action bg-transparent
    /*border: 1px solid var(--speech_analitics);*/
    /*background-color: transparent;*/
}

.react-datepicker__time-list-item:hover {
    @apply bg-basic_app_bg
}
.react-datepicker__aria-live {
    @apply hidden
}
.react-datepicker__day--disabled {
    color: rgb(203, 213, 225)!important;
    pointer-events: none!important;
}


.mediumScrollBar {
    scrollbar-width: thin;
    scrollbar-color: #F04C23 transparent;
}

.mediumScrollBar::-webkit-scrollbar {
    width: 20px !important;
}

.mediumScrollBar::-webkit-scrollbar-track {
    @apply bg-basic_app_bg rounded-[10px];
}

.mediumScrollBar::-webkit-scrollbar-thumb {
    @apply bg-scroll rounded-[10px] border-none;
}

.mediumScrollBar::-webkit-scrollbar {
    width: 7px !important;
    height: 7px !important;
}
.inputLabel  {
    @apply font-[500] w-[269px] text-[14px] leading-[110%] tracking-tight text-1color
}